import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import Image from "next/image";

export default function Index({}: InferGetServerSidePropsType<
  typeof getServerSideProps
>) {
  return (
    <div className="container">
      <Image
        src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
        alt="LouiseBot Logo"
        className="rotating-logo"
        width={100}
        height={100}
      />
      <a
        href="https://LouiseBot.com"
        target="_blank"
        rel="noopener noreferrer"
        className="logo-text"
      >
        LouiseBot.com
      </a>
      <small className="widget-version">
        {process.env.NEXT_PUBLIC_APP_VERSION}
      </small>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = async () => {
    return {
        props: {},
    };
};
